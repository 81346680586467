<template>
  <div id="LocalDepositor" class="form-bottom-row line clearfix">
    <div class="fr img-row">
      <img src="@/assets/images/withdraw/vietna_icon.png" alt="vietna_icon" />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form-list clearfix">
          <li class="fl">
            <SelectForm
              v-model="TransferForm.selectedCardID"
              :label="$t('withdraw.default.selectBA')"
              name="selectedCardID"
              data-testid="selectedCardID"
              class="plain-select"
              popperClass="withdraw-select"
            >
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, withdrawalType, dropdownTranslationObj)"
                :data-testid="`select_${item.id}`"
              ></el-option>
            </SelectForm>
          </li>
        </ul>
        <div v-if="TransferForm.selectedCardID || TransferForm.selectedCardID === 0">
          <ul class="form-list clearfix">
            <li>
              <el-form-item :label="$t('common.field.accName')" prop="bankAccountName">
                <el-input
                  v-model="TransferForm.bankAccountName"
                  class="plain-input"
                  :disabled="isdisabled"
                  data-testid="bankAccountName"
                />
              </el-form-item>
            </li>
            <li>
              <el-form-item :label="$t('common.field.bankName')" prop="bankName">
                <el-input
                  v-model="TransferForm.bankName"
                  class="plain-input"
                  :disabled="isdisabled"
                  data-testid="bankName"
                />
              </el-form-item>
            </li>
            <li>
              <el-form-item :label="$t('common.field.reservedMobileNumber')" prop="phoneNumber">
                <el-input
                  v-model="TransferForm.phoneNumber"
                  class="plain-input"
                  :disabled="isdisabled"
                  data-testid="phoneNumber"
                />
              </el-form-item>
            </li>
            <li v-if="isJOD()">
              <el-form-item :label="$t('common.field.alias')" prop="alias">
                <el-input v-model="TransferForm.alias" class="plain-input" :disabled="isdisabled" data-testid="alias" />
              </el-form-item>
            </li>
            <li>
              <el-form-item :label="$t('common.field.accNum')" prop="accountNumber">
                <el-input
                  v-model="TransferForm.accountNumber"
                  class="plain-input"
                  :disabled="isdisabled"
                  data-testid="accountNumber"
                />
              </el-form-item>
            </li>
            <li v-if="isUAE()">
              <el-form-item :label="$t('common.field.IBAN')" prop="iban">
                <el-input v-model="TransferForm.iban" class="plain-input" :disabled="isdisabled" data-testid="iban" />
              </el-form-item>
            </li>
            <li>
              <SelectForm
                v-model="TransferForm.eCode"
                name="eCode"
                :label="$t('deposit.localdepositor.depositor')"
                :disabled="isdisabled"
                :tip="true"
                class="plain-select"
                popperClass="withdraw-select"
              >
                <el-option
                  v-for="item in eCodeList"
                  :key="item.ecode"
                  :label="item.name"
                  :value="item.ecode"
                ></el-option>
                <template v-slot:tip>
                  <p>{{ $t('deposit.localdepositor.withdrawTip') }}</p>
                </template>
              </SelectForm>
            </li>
            <li>
              <el-form-item :label="$t('common.field.imptNotes')" prop="imptNotes">
                <el-input v-model="TransferForm.notes" class="plain-input" :disabled="isdisabled" data-testid="notes" />
              </el-form-item>
            </li>
          </ul>
          <div class="checkbox-wrapper" v-if="!TransferForm.userPaymentInfoId">
            <el-checkbox v-model="isRememberInfo" data-testid="rememberInfo">
              {{ $t('withdraw.default.remember') }}</el-checkbox
            >
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import SelectForm from '@/components/form/Select';
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import { apiLocalDepositor_depositor } from '@/resource';
import { countryCodeEnum } from '@/constants/country';
export default {
  name: 'LocalDepositorWithdraw',
  components: { SelectForm },
  props: {
    accountNumber: Number,
    amount: Number,
    withdrawalType: Number,
    followerResults: Array
  },
  mixins: [bankTransferMixin, withdrawlMixin],
  data() {
    return {
      TransferForm: {
        bankAccountName: '',
        bankName: '',
        accountNumber: null,
        alias: null,
        phoneNumber: null,
        iban: null,
        eCode: '',
        notes: '',
        userPaymentInfoId: null
      },
      TransferFormRules: {
        selectedCardID: [
          {
            required: true,
            message: this.$t('common.formValidation.selectBAReq'),
            trigger: 'blur'
          }
        ],
        bankAccountName: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('common.field.accName')
            }),
            trigger: 'blur'
          }
        ],
        bankName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankNameReq'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccount,
            trigger: ['blur', 'change']
          }
        ],
        alias: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('common.field.alias')
            }),
            trigger: 'blur'
          }
        ],
        phoneNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.phoneNumberReq'),
            trigger: 'blur'
          }
        ],
        iban: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('common.field.IBAN')
            }),
            trigger: 'blur'
          }
        ],
        eCode: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('deposit.localdepositor.depositor')
            }),
            trigger: 'blur'
          }
        ]
      },
      eCodeList: []
    };
  },
  computed: {
    countryCode() {
      return parseInt(this.$store.state.common.countryCode);
    }
  },
  watch: {
    'TransferForm.selectedCardID'(value) {
      let selectedCardInfo = this.availableCards.find(item => item.id === value);
      let isCurrentCard = selectedCardInfo && selectedCardInfo.id !== -1;
      this.TransferForm = {
        selectedCardID: value,
        bankAccountName: isCurrentCard ? selectedCardInfo.bankAccountName : '',
        bankName: isCurrentCard ? selectedCardInfo.bankName : '',
        accountNumber: isCurrentCard ? selectedCardInfo.accountNumber : '',
        alias: isCurrentCard && this.isJOD() ? selectedCardInfo.accountNumber : '',
        phoneNumber: isCurrentCard ? selectedCardInfo.phoneNumber : '',
        financialInstitutionNumber: isCurrentCard ? selectedCardInfo.iban : '',
        eCode: isCurrentCard ? selectedCardInfo.localDepositor : '',
        notes: isCurrentCard ? selectedCardInfo.importantNotes : '',
        userPaymentInfoId: isCurrentCard ? selectedCardInfo.id : null
      };
      this.isdisabled = isCurrentCard ? true : false;
    },
    'TransferForm.accountNumber'(value) {
      this.TransferForm.accountNumber = this.latinNumberValidator(value);
    },
    'TransferForm.bankBranch'(value) {
      this.TransferForm.bankBranch = this.latinNumberValidator(value);
    }
  },
  mounted() {
    this.getDepositor();
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        bankAccountName: this.TransferForm.bankAccountName,
        bankName: this.TransferForm.bankName,
        accountNumber: this.isJOD() ? this.TransferForm.alias : this.TransferForm.accountNumber,
        phoneNumber: this.TransferForm.phoneNumber,
        financialInstitutionNumber: this.TransferForm.iban,
        localDepositor: this.TransferForm.eCode,
        importantNotes: this.TransferForm.notes,
        isRememberInfo: this.TransferForm.userPaymentInfoId ? '' : this.isRememberInfo,
        userPaymentInfoId: this.TransferForm.userPaymentInfoId ? this.TransferForm.userPaymentInfoId : '',
        followerResultIds: this.followerResults
      };
    },
    getDepositor() {
      return apiLocalDepositor_depositor().then(resp => {
        if (resp.data.code == 0) {
          this.eCodeList = resp.data.data;
        }
      });
    },
    isJOD() {
      return this.countryCode === countryCodeEnum.JORDAN;
    },
    isUAE() {
      return this.countryCode === countryCodeEnum.UNITED_ARAB_EMIRATES;
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/components/common/SelectLine';
</style>
